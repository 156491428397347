import { xApiKey } from "../config/config";
import axios from "./axios";
import { api } from "./axios.config";
const POLLER_API_URL = api.pollerApiUrl;
const API_URL = api.apiUrl;
const EVENT_URL = "/event/public/";
const TICKET_URL = "/event/url/";
const TICKETS = "/tickets";
const VALIDATE = "/v2/validate";
const LINKEDIN_VALIDATE = "/linkedin/validator";
const CAPTURE = "/event/capture/v2";
const DISCOUNT = eventId => `/event/${eventId}/coupons/validate`;
const LEADS = eventId => `/event/${eventId}/leads`;
const ORDER = eventId => `/event/${eventId}/order`;
const GET_WAITLIST = eventUrl => `/event/public/${eventUrl}/waitlist`;
const CREATE_WAITLIST = eventId => `/event/${eventId}/waitlist`;
const OTP = eventId => `/event/${eventId}/referral/otp`;
const GET_TEAMS = eventId => `/event/${eventId}/teams/search`;
const VALIDATE_TEAMS = eventId => `/event/${eventId}/teams/validate`;

export const getEventDetails = eventUrl => {
	return axios.get(API_URL + EVENT_URL + eventUrl);
};

export const getEventTickets = (eventUrl, isReferral) => {
	const query = isReferral ? "?is_referral=true" : "";
	return axios.get(API_URL + TICKET_URL + eventUrl + TICKETS + query);
};

export const captureApi = data => {
	return axios.post(
		API_URL + CAPTURE,
		{ ...data, primary_discount_type: 2 },
		{
			headers: {
				"X-API-Key": `${xApiKey}`
			}
		}
	);
};

export const leadsApi = (eventId, data) => {
	return axios.post(API_URL + LEADS(eventId), data);
};

export const orderApi = (eventId, data) => {
	return axios.post(API_URL + ORDER(eventId), data);
};

export const validateEmail = (eventId = "", email, ticketId = "") => {
	const query = `?${eventId && `event_id=${eventId}`}&ticket_id=${ticketId}&email_id=${email}`;
	return axios.get(API_URL + VALIDATE + query);
};

export const validatePhoneNumber = (eventId, number, dialCode, ticketId = null) => {
	const cleanedDialCode = dialCode.replace(/\s+/g, "").replace("+", "").trim(); // Remove spaces and "+"
	let query = "";
	if (!ticketId) {
		query = `?event_id=${eventId}&phone_number=${number}&dial_code=${cleanedDialCode}`;
	} else {
		query = `?event_id=${eventId}&phone_number=${number}&ticket_id=${ticketId}&dial_code=${cleanedDialCode}`;
	}

	return axios.get(API_URL + VALIDATE + query);
};

export const validateLinkedin = linkedinUrl => {
	const query = `?linkedin-url=${linkedinUrl}`;
	return axios.get(API_URL + LINKEDIN_VALIDATE + query);
};

export const validateDiscountCode = (eventId, couponCode) => {
	const query = `?coupon_code=${couponCode}`;
	return axios.get(API_URL + DISCOUNT(eventId) + query);
};

export const getWaitlist = (eventUrl, bookingId, isWaitlist = true, ticketId) => {
	const query = `?booking_id=${bookingId}&ticket_id=${ticketId}&is_waitlist=${isWaitlist}`;
	return axios.get(API_URL + GET_WAITLIST(eventUrl) + query);
};
export const createWaitlist = (data, eventId, isWaitlist = true) => {
	const query = `?is_waitlist=${isWaitlist}`;
	return axios.post(API_URL + CREATE_WAITLIST(eventId) + query, data);
};

export const sendOTP = (eventId, eventName, emailId) => {
	const query = `?email_id=${emailId}&event_name=${eventName}`;
	return axios.get(API_URL + OTP(eventId) + query);
};

export const validateOTP = (eventId, data) => {
	return axios.post(API_URL + OTP(eventId), data);
};

export const createCheckInFace = (eventId, data) => {
	const query = `/${eventId}/attendee/face`;
	return axios.post(API_URL + query, data, {
		headers: {
			"X-API-Key": `${xApiKey}`
		}
	});
};

export const stripeAPI = (URL, data) => {
	return axios.post(URL, data);
};

export const validateCustomForm = (eventId, ticket_id, form_id, form_value) => {
	const query = `?${`event_id=${eventId}`}&ticket_id=${ticket_id}&form_id=${form_id}&form_value=${form_value}`;
	return axios.get(API_URL + VALIDATE + "/custom-form" + query);
};

// export const getEventAndTicketDetails = (eventUrl) => {
//     axios.all([
//         axios.get(API_URL + EVENT_URL + eventUrl + TICKETS),
//         axios.get(API_URL + EVENT_URL + eventUrl)
//       ])
//       .then(axios.spread((ticketDetails, eventDetails) => {
//         // Both requests are now complete
//         return [ticketDetails, eventDetails]
//       }));
// }

export const callPollerApi = KH_ORDER_ID => {
	return axios.get(`${POLLER_API_URL}/event/${KH_ORDER_ID}/status`);
};

export const searchTeam = (eventId, search_term, limit = 20, offset = 0) => {
	const query = `?search_term=${search_term}&limit=${limit}&offset=${offset}`;
	return axios.get(API_URL + GET_TEAMS(eventId) + query);
};

export const validateTeamName = (eventId, team_name) => {
	const query = `?team_name=${team_name}`;
	return axios.get(API_URL + VALIDATE_TEAMS(eventId) + query);
};
